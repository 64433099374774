@import url("https://fonts.googleapis.com/css?family=Raleway:200,700|Source+Sans+Pro:300,600,300italic,600italic");

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    font-family: Raleway, Helvetica, sans-serif;
}


// body,
// input,
// select,
// textarea {
//     color: #fff;
//     font-family: "Source Sans Pro", Helvetica, sans-serif;
//     font-size: 16.5pt;
//     font-weight: 300;
//     line-height: 1.65;
// }
// @media screen and (max-width: 1680px) {
//     body,
//     input,
//     select,
//     textarea {
//         font-size: 13pt;
//     }
// }
// @media screen and (max-width: 1280px) {
//     body,
//     input,
//     select,
//     textarea {
//         font-size: 12pt;
//     }
// }
// @media screen and (max-width: 980px) {
//     body,
//     input,
//     select,
//     textarea {
//         font-size: 12pt;
//     }
// }
// @media screen and (max-width: 736px) {
//     body,
//     input,
//     select,
//     textarea {
//         font-size: 12pt;
//     }
// }
// @media screen and (max-width: 480px) {
//     body,
//     input,
//     select,
//     textarea {
//         font-size: 12pt;
//     }
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     color: #fff;
//     font-family: Raleway, Helvetica, sans-serif;
//     font-weight: 700;
//     letter-spacing: 0.1em;
//     margin: 0 0 1em 0;
//     text-transform: uppercase;
// }

// h2 {
//     font-size: 1.65em;
//     font-weight: 400;
// }

// p {
//     margin: 0 0 2em 0;
// }